import Section from "../Section";
import BoxPoint from "../BoxPoint";
import { useEffect, useState } from "react";
import axios from "axios";

interface IMission {
  id: string;
  text: string;
  vissionId: 1;
}

const VisiMisi = () => {
  const [vission, setVission] = useState("");
  const [missions, setMissions] = useState<IMission[]>([]);

  useEffect(() => {
    const getVission = async () => {
      try {
        const { data } = await axios(`${process.env.REACT_APP_API_URL}vission`);
        return data;
      } catch (error: any) {
        throw new Error(error.message);
      }
    };

    const getMissions = async () => {
      try {
        const { data } = await axios(`${process.env.REACT_APP_API_URL}mission`);
        return data;
      } catch (error: any) {
        throw new Error(error.message);
      }
    };

    const getVissionMissions = async () => {
      const response = await Promise.all([
        await getVission(),
        await getMissions(),
      ]);

      setVission(response[0].text);
      setMissions(response[1]);
    };

    getVissionMissions();
  }, []);

  return (
    <Section title="Visi / Misi" id="visi-misi">
      <div>
        <h4 className="font-bold text-primary-green text-lg text-center">
          {vission && vission}
        </h4>
        <h4 className="font-bold text-primary-green text-lg text-center mt-4 mb-2">
          Misi-misi adalah sebagai berikut:
        </h4>
      </div>
      <div>
        <ul className="flex flex-col md:flex-row flex-wrap gap-4">
          {missions.length > 0 &&
            missions.map((mission, index) => (
              <li key={mission.id} className="shrink-0 md:basis-[48%]">
                <BoxPoint point={index + 1} desc={mission.text} />
              </li>
            ))}
        </ul>
      </div>
    </Section>
  );
};
export default VisiMisi;
