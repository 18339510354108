// import CustomReactQuill from "../CustomReactQuill";
import { useEffect, useState } from "react";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditorComponent from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";

interface ITupoksiData {
  id: string;
  title: string;
  textarea: string;
}

const TupoksiCard = ({ data }: { data: ITupoksiData }) => {
  const [title, setTitle] = useState(data.title);
  const [textarea, setTextarea] = useState(data.textarea);

  const handleModelChange = (event: any) => {
    setTextarea(event);
  };

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  return (
    <>
      <div className="flex justify-between items-center rounded-md border-2 border-gray-500 pl-2 pr-4 py-2">
        <input className="form-data" type="hidden" value={data.id} />
        <input
          type="text"
          placeholder="Judul..."
          className="w-full form-data"
          onChange={onChangeTitle}
          value={title}
        />
      </div>
      <input className="form-data" type="hidden" value={textarea} />
      {/* <CustomReactQuill value={textarea} onChange={setTextarea} /> */}
      <FroalaEditorComponent
        tag="textarea"
        onModelChange={handleModelChange}
        model={textarea}
      />
    </>
  );
};

export default TupoksiCard;
