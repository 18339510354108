import axios from "axios";
import Section from "../Section";
import LinkCard from "./LinkCard";
import { useEffect, useState } from "react";
import NavBack from "../NavBack";

const Lainnya = ({ title, category }: { title: string; category: string }) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const getFiles = async () => {
      try {
        const { data } = await axios(
          `${process.env.REACT_APP_API_URL}files/${category}`,
          { method: "GET" }
        );
        setFiles(data);
      } catch (error: any) {
        throw new Error(error.message);
      }
    };
    getFiles();
  }, [category]);

  return (
    <>
      <NavBack />
      <Section title={title} id="lain-lain">
        <div className="flex flex-col gap-4">
          {files.length > 0 &&
            files.map((item: any) => (
              <LinkCard
                key={item.id}
                title={item.title}
                link={process.env.REACT_APP_SERVER_URL + item.filePath}
              />
            ))}
        </div>
      </Section>
    </>
  );
};
export default Lainnya;
