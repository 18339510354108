import Section from "../Section";

const Berita = () => {
  return (
    <Section title="Berita" id="berita">
      <div>
        <iframe
          src="https://id.litbangkestanbu.com/list-berita-3/"
          className="w-full h-screen border-0"
          title="berita-3"
        ></iframe>
      </div>
      {/* <div className="flex justify-center items-center">
        <ButtonGreenInverse
          link="https://id.litbangkestanbu.com/berita/"
          text="Lainnya"
        />
      </div> */}
    </Section>
  );
};
export default Berita;
