import { useEffect, useState } from "react";
import AdminHeader from "../admin/AdminHeader";
import axios from "axios";

type IDataFiles = {
  id?: number;
  title: string | undefined;
  fileName: string | undefined;
  fileAttach: File | undefined;
  filePath?: string;
  category: string;
};

const AdminStrukturOrganisasi = () => {
  const [file, setFile] = useState<IDataFiles>();

  useEffect(() => {
    const getFile = async () => {
      try {
        const { data } = await axios(
          `${process.env.REACT_APP_API_URL}files/struktur-organisasi`,
          {
            method: "GET",
          }
        );
        if (data.length > 0) {
          return setFile({ ...data[0], fileAttach: undefined });
        }

        return setFile({
          title: "",
          fileName: undefined,
          fileAttach: undefined,
          filePath: undefined,
          category: "struktur-organisasi",
        });
      } catch (error: any) {
        throw new Error(error.message);
      }
    };

    getFile();
  }, []);

  const onSave = async () => {
    const formData = new FormData();
    if (!file?.fileAttach) return console.log("Tidak ada file yang diupload.");

    formData.append("fileAttach", file.fileAttach as File);
    formData.append("category", "struktur-organisasi");

    try {
      const { data } = await axios(
        `${process.env.REACT_APP_API_URL}files/upload/struktur-organisasi`,
        {
          method: "POST",
          data: formData,
        }
      );

      setFile({ ...data, fileAttach: undefined });
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files?.[0];
    setFile((prev) => ({
      ...prev,
      fileAttach: file,
      fileName: file?.name,
      title: file?.name,
      category: "struktur-organisasi",
    }));
  };

  return (
    <div>
      <AdminHeader title="Struktur Organisasi" onSave={onSave} />
      <input
        type="file"
        name="fileAttach"
        accept="image/*"
        onChange={onChangeFile}
        className="mb-4 text-transparent w-28"
      />
      <label htmlFor="fileAttach">{file && file?.fileName}</label>
      {file && file?.filePath && (
        <div className="w-[800px]">
          <img
            src={process.env.REACT_APP_SERVER_URL + file.filePath}
            alt="preview"
            className="object-cover"
          />
        </div>
      )}
    </div>
  );
};

export default AdminStrukturOrganisasi;
