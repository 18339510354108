import { useEffect, useState } from "react";
import AdminHeader from "../admin/AdminHeader";
import groupArray from "../../utils/groupArray";
import AdminVissionComponent from "../admin/AdminVissionComponent";
import AdminMissionComponent from "../admin/AdminMissionComponent";
import axios from "axios";
import { Oval } from "react-loader-spinner";

interface IMissionsData {
  id: string;
  newData: boolean;
  text: string;
  vissionId: number;
}

interface IData {
  vission: string;
  missions: IMissionsData[];
  isLoading: boolean;
}

const getVission = async () => {
  const { data } = await axios.get(process.env.REACT_APP_API_URL + "vission");
  return data;
};

const getMissions = async () => {
  const { data } = await axios.get(process.env.REACT_APP_API_URL + "mission");
  return data;
};

const AdminVisiMisi = () => {
  const [data, setData] = useState<IData>({
    vission: "",
    missions: [],
    isLoading: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      const [vission, missions] = await Promise.all([
        getVission(),
        getMissions(),
      ]);

      setData((prev) => ({ ...prev, vission: vission.text }));

      if (missions.length > 0) {
        setData((prev) => ({
          ...prev,
          missions: missions.map((mission: IData) => ({
            ...mission,
            newData: false,
          })),
        }));
      } else {
        setData((prev) => ({ ...prev, missions }));
      }

      setData((prev) => ({ ...prev, isLoading: false }));
    };

    fetchData();
  }, []);

  const onSave = async () => {
    const data = Array.prototype.slice.call(document.querySelectorAll("input"));
    const dataVission = data[0].value;
    if (!dataVission) {
      return console.log("Vission is empty!");
    }

    const dataMissionsHTMLElements = groupArray<HTMLInputElement>(
      data.slice(1),
      3
    );
    const dataMissions = dataMissionsHTMLElements.map(
      (dataMissionHTMLElement) => {
        const [idElement, newDataElement, textElement] = dataMissionHTMLElement;
        return {
          id: idElement.value,
          newData: newDataElement.checked,
          text: textElement.value,
          vissionId: 1,
        };
      }
    );

    if (!dataMissions.length) {
      return console.log("Missions is empty!");
    }

    const checkDataMissions = dataMissions.every((dataMission) => {
      return dataMission.text.length > 0;
    });

    if (!checkDataMissions) {
      return console.log("Some of Mission is empty!");
    }

    setData((prev) => ({ ...prev, isLoading: true }));

    const updateVission = async () => {
      return await axios(process.env.REACT_APP_API_URL + "vission", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
        data: {
          text: dataVission,
        },
      });
    };

    const updateMissions = () => {
      return dataMissions.map(async (mission) => {
        if (mission.newData) {
          return await axios(process.env.REACT_APP_API_URL + "mission", {
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
            data: {
              id: mission.id,
              text: mission.text,
              vissionId: 1,
            },
          });
        }

        return await axios(
          process.env.REACT_APP_API_URL + `mission/${mission.id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "PATCH",
            data: {
              text: mission.text,
              vissionId: mission.vissionId,
            },
          }
        );
      });
    };

    Promise.all([updateVission(), ...updateMissions()]).then((result) => {
      setData((prev) => ({
        ...prev,
        vission: dataVission,
        missions: dataMissions,
        isLoading: false,
      }));
    });
  };

  if (data.isLoading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Oval
          height={80}
          width={80}
          color="#21928F"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#21928FCC"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );

  return (
    <>
      <AdminHeader title="Visi / Misi" onSave={onSave} />
      <AdminVissionComponent vission={data.vission} setData={setData} />
      <div className="mt-4">
        <AdminMissionComponent missions={data.missions} setData={setData} />
      </div>
    </>
  );
};

export default AdminVisiMisi;
