import LinkList from "../main/LinkList";

interface IDataFiles {
  id: number;
  title: string;
  filePath: string;
  fileName: string;
  category: string;
}

interface ILinkListWithSectionParams {
  title: string;
  data: IDataFiles[] | [];
  priority?: boolean;
  link: string;
  id: string;
}

const LinkListWithSection = ({
  title,
  data,
  priority,
  link,
  id,
}: ILinkListWithSectionParams) => {
  return (
    <div className={priority ? "mb-4" : ""} id={id}>
      <section className="mx-auto pb-16 mt-16 bg-white">
        <div className="py-8 lg:mb-8">
          <h3 className="text-2xl text-center text-primary-green font-bold uppercase mb-4">
            {title}
          </h3>
          <div className="w-full h-0.5 bg-gray-400/10 rounded-full" />
        </div>
        {data.length > 0 ? (
          <LinkList data={data} priority={priority} link={link} />
        ) : undefined}
      </section>
    </div>
  );
};
export default LinkListWithSection;
