import { Link } from "react-router-dom";

interface ICTAButtonTransparentParams {
  link: string;
  text: string;
  targetBlank?: boolean;
}

const CTAButtonTransparent = ({
  link,
  text,
  targetBlank,
}: ICTAButtonTransparentParams) => {
  return (
    <Link
      to={link}
      target={targetBlank ? "_blank" : "_self"}
      className="px-4 py-2 bg-transparent rounded-lg flex gap-3 mx-auto border-2 border-primary-green items-center w-fit"
    >
      <span className="font-bold text-primary-green text-lg">{text}</span>
      <img
        src="/assets/icons/green-right.svg"
        alt="green right"
        height={40}
        width={40}
      />
    </Link>
  );
};
export default CTAButtonTransparent;
