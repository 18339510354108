import LinkCard from "../main/LinkCard";
import ButtonGreenInverse from "../ButtonGreenInverse";

interface IDataFiles {
  id: number;
  title: string;
  filePath: string;
  fileName: string;
  category: string;
}

interface ILinkListParams {
  data: IDataFiles[] | [];
  priority?: boolean;
  link: string;
}

const LinkList = ({ data, priority, link }: ILinkListParams) => {
  return (
    <div className="flex flex-col gap-4 w-full">
      {data.length > 0 && data.length > 3
        ? data
            .slice(0, 3)
            .map((item) => (
              <LinkCard
                key={item.id}
                title={item.title}
                link={process.env.REACT_APP_SERVER_URL + item.filePath}
                priority={priority}
              />
            ))
        : data.map((item) => (
            <LinkCard
              key={item.id}
              title={item.title}
              link={process.env.REACT_APP_SERVER_URL + item.filePath}
              priority={priority}
            />
          ))}
      {data.length > 0 && (
        <div className="flex justify-center items-center">
          <ButtonGreenInverse link={link} text="Lainnya" />
        </div>
      )}
    </div>
  );
};
export default LinkList;
