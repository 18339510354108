import { MdDelete } from "react-icons/md";

type IDataFiles = {
  id?: number;
  title: string | undefined;
  fileName: string | undefined;
  fileAttach: File | undefined;
  category: string;
};

interface IPDFCardParams {
  order: number;
  data: IDataFiles;
  onDelete: () => void;
  onChangeFiles: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PDFCard = ({
  order,
  data,
  onDelete,
  onChangeFiles,
  onChangeTitle,
}: IPDFCardParams) => {
  return (
    <div className="flex justify-between items-center rounded-md border-2 border-gray-500 pl-2 pr-4 py-2">
      <div className="flex space-x-2 items-center w-full mr-2">
        <div className="rounded-md bg-primary-green text-white px-3 py-1">
          {order}
        </div>
        <input
          type="text"
          className="w-full"
          name={`name-${order}`}
          onChange={onChangeTitle}
          value={data.title}
        />
      </div>
      <input
        type="file"
        name={`file-${order}`}
        accept="application/msword, application/vnd.ms-excel, application/pdf"
        onChange={onChangeFiles}
        className="text-transparent w-36"
      />
      <label htmlFor={`file-${order}`} className="whitespace-nowrap mr-4">
        {data.fileName
          ? `...${
              data.fileName.length < 17
                ? data.fileName
                : data.fileName.slice(data.fileName.length - 17)
            }`
          : ""}
      </label>
      <button type="button" onClick={onDelete}>
        <span className="text-red-600">
          <MdDelete />
        </span>
      </button>
    </div>
  );
};
export default PDFCard;
