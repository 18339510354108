import { useNavigate } from "react-router-dom";

const NavBack = () => {
  const navigate = useNavigate();

  return (
    <nav className="fixed top-0 z-50 w-full transition-all duration-500 bg-white">
      <div className="container mx-auto py-2 flex justify-between items-start lg:items-center w-full">
        {/* Logo */}
        <div>
          <img
            src="/assets/images/logo.svg"
            alt="logo"
            height={64}
            width={64}
          />
        </div>

        {/* back button */}
        <div>
          <button onClick={() => navigate(-1)}>Kembali ke beranda</button>
        </div>
      </div>
    </nav>
  );
};
export default NavBack;
