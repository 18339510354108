export const menuItems = [
  {
    title: "Beranda",
    url: "/",
  },
  {
    title: "Profil",
    url: "#profil",
    submenu: [
      { title: "Sejarah", url: "#sejarah" },
      { title: "Visi Misi", url: "#visi-misi" },
      { title: "Struktur Organisasi", url: "#struktur-organisasi" },
      { title: "Tupoksi", url: "#tupoksi" },
    ],
  },
  {
    title: "Zona Integritas",
    url: "#zona-integritas",
  },
  {
    title: "Informasi",
    url: "#informasi",
    submenu: [
      { title: "Pengumuman", url: "#pengumuman" },
      { title: "Berita", url: "#berita" },
      { title: "Format Surat", url: "#format-surat" },
      {
        title: "Informasi Berkala",
        url: "#informasi-berkala",
        submenu: [
          { title: "Laporan Tahunan", url: "#laporan-tahunan" },
          { title: "Laporan Kinerja", url: "#laporan-kinerja" },
          { title: "Rencana Kegiatan", url: "#rencana-kegiatan" },
          { title: "Keuangan", url: "#keuangan" },
          { title: "Kearsipan", url: "#kearsipan" },
          { title: "Lainnya", url: "#lain-lain" },
        ],
      },
    ],
  },
  {
    title: "Galeri",
    url: "#galeri",
  },
];
