import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const BeritaManagement = () => {
  const navigate = useNavigate();

  return (
    <div>
      <button
        className="bg-[#2F80ED] px-3 py-2 rounded-md text-white font-bold flex justify-center items-center space-x-2"
        type="button"
        onClick={() => {
          navigate("/admin/berita/create");
        }}
      >
        <div className="p-1 rounded-md bg-white text-primary-green">
          <MdAdd size={20} />
        </div>
        <span>Buat Artikel Baru</span>
      </button>
      <table className="w-full mt-4 berita_table">
        <colgroup>
          <col span={1} className="w-[60%]" />
          <col span={1} className="w-[15%]" />
          <col span={1} className="w-[25%]" />
        </colgroup>
        <thead>
          <tr>
            <th className="text-left">Judul</th>
            <th className="text-left">Tanggal</th>
            <th className=""></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Amanat WBK tahun 2019</td>
            <td>2023/12/31</td>
            <td className="space-x-2 flex items-center justify-center">
              <button className="bg-primary-green w-20 py-1 text-white font-bold rounded-lg">
                Edit
              </button>
              <button className="bg-red-600 w-20 py-1 text-white font-bold rounded-lg">
                Hapus
              </button>
            </td>
          </tr>
          <tr>
            <td>Amanat WBK tahun 2019</td>
            <td>2023/12/31</td>
            <td className="space-x-2 flex items-center justify-center">
              <button className="bg-primary-green w-20 py-1 text-white font-bold rounded-lg">
                Edit
              </button>
              <button className="bg-red-600 w-20 py-1 text-white font-bold rounded-lg">
                Hapus
              </button>
            </td>
          </tr>
          <tr>
            <td>Amanat WBK tahun 2019</td>
            <td>2023/12/31</td>
            <td className="space-x-2 flex items-center justify-center">
              <button className="bg-primary-green w-20 py-1 text-white font-bold rounded-lg">
                Edit
              </button>
              <button className="bg-red-600 w-20 py-1 text-white font-bold rounded-lg">
                Hapus
              </button>
            </td>
          </tr>
          <tr>
            <td>Amanat WBK tahun 2019</td>
            <td>2023/12/31</td>
            <td className="space-x-2 flex items-center justify-center">
              <button className="bg-primary-green w-20 py-1 text-white font-bold rounded-lg">
                Edit
              </button>
              <button className="bg-red-600 w-20 py-1 text-white font-bold rounded-lg">
                Hapus
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default BeritaManagement;
