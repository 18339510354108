import MenuMobile from "../components/MenuMobile";
// import MenuDesktop from "../components/MenuDesktop";
import { Link } from "react-router-dom";

// import { useState } from "react";

// const Nav = () => {
// const [openMobileMenu, setOpenMobileMenu] = useState(false);

// const openMenu = () => {
//   setOpenMobileMenu(true);
// };

//   return (
//     <nav className="fixed top-0 z-50 w-full transition-all duration-500 bg-transparent">
//       <div className="container mx-auto py-2 flex justify-between items-start lg:items-center w-full">
//         {/* Logo */}
//         <div>
//           <img
//             src="/assets/images/logo.svg"
//             alt="logo"
//             height={64}
//             width={64}
//           />
//         </div>

//         {/* Menu */}

//         {/* Desktop Menu */}
// <div className="hidden flex-1 lg:flex">
//   <MenuDesktop />
// </div>

// <div>
//   {/* Mobile Menu */}
//   <div className="lg:hidden">
//     <img
//       src="/assets/icons/menu.svg"
//       alt="menu"
//       height={56}
//       width={56}
//       onClick={openMenu}
//     />
//   </div>
//   <MenuMobile
//     openMobileMenu={openMobileMenu}
//     setOpenMobileMenu={setOpenMobileMenu}
//   />
// </div>
//       </div>
//     </nav>
//   );
// };
// export default Nav;

import { useState, useEffect, useRef } from "react";
import { menuItems } from "../utils/menuItems";

const Dropdown = ({ submenus, dropdown, depthLevel }: any) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
  return (
    <ul
      className={`dropdown text-primary-green ${dropdownClass} ${
        dropdown ? "show" : ""
      }`}
    >
      {submenus.map((submenu: any, index: any) =>
        submenu.submenu ? (
          <MenuItems items={submenu} key={index} depthLevel={depthLevel} />
        ) : (
          <li key={index} className="menu-items">
            <a href={submenu.url}>{submenu.title}</a>
          </li>
        )
      )}
    </ul>
  );
};

const MenuItems = ({ items, depthLevel }: any) => {
  const [dropdown, setDropdown] = useState(false);
  let ref: any = useRef();
  const textColorClass = depthLevel > 0 ? "text-primary-green" : "text-white";

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  useEffect(() => {
    const handler = (event: any) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  return (
    <li
      className="menu-items text-white"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.submenu ? (
        <>
          <button
            className="text-primary-green"
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            <span className={textColorClass}>{items.title}</span>{" "}
            {depthLevel > 0 ? (
              <span className="text-primary-green">&raquo;</span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            submenus={items.submenu}
            dropdown={dropdown}
            depthLevel={depthLevel}
          />
        </>
      ) : (
        <a href={items.url}>{items.title}</a>
      )}
    </li>
  );
};

const Navbar = () => {
  return (
    <div>
      <ul className="menus">
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
    </div>
  );
};

const Nav = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const openMenu = () => {
    setOpenMobileMenu(true);
  };

  return (
    <nav className="fixed top-0 z-50 w-full transition-all duration-500 bg-transparent">
      <div className="container mx-auto py-2 flex justify-between items-start lg:items-center w-full">
        <Link to="/">
          <img
            src="/assets/images/logo.svg"
            alt="logo"
            height={64}
            width={64}
          />
        </Link>

        <div className="hidden lg:block">
          <Navbar />
        </div>

        <div className="lg:hidden">
          <div>
            <img
              src="/assets/icons/menu.svg"
              alt="menu"
              height={56}
              width={56}
              onClick={openMenu}
            />
          </div>
          <MenuMobile
            openMobileMenu={openMobileMenu}
            setOpenMobileMenu={setOpenMobileMenu}
          />
        </div>
      </div>
    </nav>
  );
};
export default Nav;
