import { useEffect, useState } from "react";
import LinkListWithSection from "./LinkListWithSection";
import axios from "axios";

interface IDataFiles {
  id: number;
  title: string;
  fileName: string;
  filePath: string;
  category: string;
}

interface IFiles {
  title: string;
  data: IDataFiles[] | [];
  category: string;
}

const Informasi = () => {
  const [files, setFiles] = useState<IFiles[]>([]);

  useEffect(() => {
    const fetchData = async (slugCategory: string) => {
      try {
        return await axios(
          `${process.env.REACT_APP_API_URL}files/${slugCategory}`,
          { method: "GET" }
        );
      } catch (error: any) {
        throw new Error(error.message);
      }
    };

    const getFiles = async () => {
      try {
        const response = await Promise.all([
          await fetchData("pengumuman"),
          await fetchData("format-surat"),
          await fetchData("laporan-tahunan"),
          await fetchData("laporan-kinerja"),
          await fetchData("rencana-kegiatan"),
          await fetchData("keuangan"),
          await fetchData("kearsipan"),
          await fetchData("lain-lain"),
        ]);
        const files = response.map((response) => response.data);
        setFiles([
          {
            title: "Pengumuman",
            data: files[0],
            category: "pengumuman",
          },
          {
            title: "Format Surat",
            data: files[1],
            category: "format-surat",
          },
          {
            title: "Laporan Tahunan",
            data: files[2],
            category: "laporan-tahunan",
          },
          {
            title: "Laporan Kinerja",
            data: files[3],
            category: "laporan-kinerja",
          },
          {
            title: "Rencana Kegiatan",
            data: files[4],
            category: "rencana-kegiatan",
          },
          {
            title: "Keuangan",
            data: files[5],
            category: "keuangan",
          },
          {
            title: "Kearsipan",
            data: files[6],
            category: "kearsipan",
          },
          {
            title: "Lain-lain",
            data: files[7],
            category: "lain-lain",
          },
        ]);
      } catch (error: any) {
        throw new Error(error.message);
      }
    };
    getFiles();
  }, []);

  return (
    <section className="container mx-auto">
      {files.length > 0 && (
        <LinkListWithSection
          data={files[0].data}
          title={files[0].title}
          priority={true}
          link={`/lainnya/${files[0].category}`}
          id={files[0].category}
        />
      )}
      {files.length > 0 && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          {files.slice(1).map((file: IFiles, index) => {
            return (
              <LinkListWithSection
                key={file.category}
                data={file.data}
                title={file.title}
                link={`/lainnya/${file.category}`}
                id={file.category}
              />
            );
          })}
        </div>
      )}
    </section>
  );
};
export default Informasi;
