import { useState } from "react";
import CustomReactQuill from "../CustomReactQuill";
import AdminHeader from "./AdminHeader";
import { Oval } from "react-loader-spinner";

type IFile = {
  title?: string | undefined;
  fileName?: string | undefined;
  fileAttach?: File | undefined;
  filePath?: string;
  category?: string;
};

const BeritaCreate = () => {
  const [data, setData] = useState<{
    judul: string;
    isLoading: boolean;
    file: IFile | undefined;
    content: string;
    initialPreview: string;
  }>({
    judul: "",
    isLoading: false,
    file: undefined,
    content: "",
    initialPreview: "",
  });

  const judulChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => ({
      ...prev,
      judul: e.target.value,
    }));
  };

  const imageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileTarget: any = e.target.files?.[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileTarget);
    reader.onloadend = () => {
      setData((prev) => ({
        ...prev,
        file: {
          ...prev.file,
          fileAttach: fileTarget,
          fileName: fileTarget.name,
          category: "berita",
          title: fileTarget.name,
        },
        initialPreview: reader.result as string,
      }));
    };
  };

  const contentChange = (value: any) => {
    setData((prev) => ({
      ...prev,
      content: value,
    }));
  };

  const onSave = () => {
    setData((prev) => ({ ...prev, isLoading: true }));
    const checkJudul = data.judul.length > 0;
    const checkImage = data?.file?.fileAttach !== undefined;
    const checkContent = data.content.length > 0;

    if (!(checkJudul && checkImage && checkContent)) {
      return console.log("Tolong lengkapi terlebih dahulu.");
    }

    const formData = new FormData();
    formData.append("fileAttach", (data.file as IFile).fileAttach as File);
    formData.append("title", data.judul);
    formData.append("content", data.content);
    formData.append("category", "berita");
  };

  if (data.isLoading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Oval
          height={80}
          width={80}
          color="#21928F"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#21928FCC"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );

  return (
    <div>
      <AdminHeader title="Buat Berita" buttonText="Publish" onSave={onSave} />
      <ul className="space-y-8">
        <li>
          {/* judul */}
          <ul>
            <li>
              <p>Judul</p>
            </li>
            <li>
              <div className="flex justify-between items-center rounded-md border-2 border-gray-500 px-2 py-2">
                <input
                  type="tex"
                  name="judul"
                  value={data.judul}
                  onChange={judulChange}
                  className="w-full"
                />
              </div>
            </li>
          </ul>
        </li>
        <li>
          {/* image */}
          <ul>
            <li>
              <p>Cover / Thumbnail</p>
            </li>
            <li>
              <span className="text-gray-500/80 mr-2">
                {data?.file?.fileName ? data.file.fileName : "Belum ada gambar"}
              </span>
              <label className="bg-primary-green cursor-pointer px-3 py-1 rounded-md text-white">
                <input
                  type="file"
                  name="fileAttach"
                  accept="image/*"
                  onChange={imageChange}
                  className="hidden"
                />
                Upload
              </label>
              <div className="mt-4">
                {data?.initialPreview ? (
                  <img
                    src={data.initialPreview}
                    className="w-40 object-contain"
                    alt="initial preview"
                  />
                ) : (
                  data?.file?.filePath && (
                    <img
                      src={
                        process.env.REACT_APP_SERVER_URL + data.file.filePath
                      }
                      className="w-40 object-contain"
                      alt="preview"
                    />
                  )
                )}
              </div>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <p>Konten</p>
            </li>
            <li>
              <CustomReactQuill value={data.content} onChange={contentChange} />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
export default BeritaCreate;
