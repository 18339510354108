import { useState } from "react";
import AdminHeader from "../admin/AdminHeader";
import BeritaManagement from "./BeritaManagement";

const AdminBerita = () => {
  const [data, setData] = useState({
    beritas: [],
    isLoading: false,
  });

  const onSave = () => {
    console.log("saved");
  };

  return (
    <div>
      <AdminHeader title="Berita" onSave={onSave} showSubmit={false} />
      <BeritaManagement />
    </div>
  );
};

export default AdminBerita;
