import { Outlet } from "react-router-dom";

const AdminLayoutSlug = () => {
  return (
    <section>
      <div className="flex w-full h-screen">
        {/* <div className="w-full h-full flex flex-col overflow-y-hidden shrink-0 basis-[20%]">
          <div className="mx-auto py-2 flex justify-center items-start lg:items-center w-full bg-black">
            <div className="flex items-center space-x-4">
              <img
                src="/assets/images/logo.svg"
                alt="logo"
                height={64}
                width={64}
              />
              <p className="text-white font-semibold">Admin</p>
            </div>
          </div>

          <div className="px-2 pt-2 flex-1 bg-gray-900 text-white h-[95%]">
            <ul className="flex flex-col space-y-4 h-full overflow-y-auto hide_scrollbar">
              {menuConstants.map((menu) => (
                <Fragment key={menu.name}>
                  <li className={`py-2 px-2 rounded-sm cursor-pointer`}>
                    <Link to={menu.path}>{menu.name}</Link>
                  </li>
                </Fragment>
              ))}
            </ul>
          </div>
        </div> */}

        {/* Content */}
        <div className="w-full overflow-y-auto h-screen p-7">
          <Outlet />
        </div>
        {/* <div className="grow h-screen overflow-y-hidden shrink-0">
          <div className="h-full w-full overflow-y-auto">
            <div className="p-7 w-full h-full"> */}
        {/* </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default AdminLayoutSlug;
