import { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import PDFCard from "./PDFCard";
import { MdAdd } from "react-icons/md";
import axios from "axios";
import { Oval } from "react-loader-spinner";

type IDataFiles = {
  id?: number;
  title: string | undefined;
  fileName: string | undefined;
  fileAttach: File | undefined;
  category: string;
};

const DownloadFilesComponent = ({
  category,
  title,
}: {
  category: string;
  title: string;
}) => {
  const [data, setData] = useState<{
    files: IDataFiles[];
    isLoading: boolean;
  }>({
    files: [],
    isLoading: false,
  });
  // const [files, setFiles] = useState<IDataFiles[]>([]);

  useEffect(() => {
    const initFiles = async () => {
      try {
        const initialFiles: { data: IDataFiles[] } = await axios(
          `${process.env.REACT_APP_API_URL}files/${category}`,
          {
            method: "GET",
          }
        );
        const generateFiles = initialFiles.data.map((file) => ({
          ...file,
          fileAttach: undefined,
        }));

        setData((prev) => ({
          ...prev,
          files: generateFiles,
        }));
        // setFiles(generateFiles);
      } catch (error: any) {
        throw new Error(error.message);
      }
    };

    initFiles();
  }, [category]);

  const onSave = async () => {
    setData((prev) => ({
      ...prev,
      isLoading: true,
    }));

    if (!(data.files.length > 0)) {
      return console.log("Tidak ada file yang diupload.");
    }

    const checkTitles = data.files.every((file) => {
      if (file.title !== undefined) {
        return (file.title as string).length > 0;
      }

      return false;
    });
    if (!checkTitles) {
      return console.log("Salah satu file atau semua tidak terisi.");
    }

    const checkFiles = data.files.every((file) => {
      if (!file.id) {
        return file.fileAttach !== undefined;
      }

      return true;
    });
    if (!checkFiles) {
      return console.log("Salah satu file atau semua tidak memilih file.");
    }

    const promises = data.files.map(async (file) => {
      const formData = new FormData();

      if (file.id) {
        formData.append("id", file.id.toString());
        if (file.fileAttach !== undefined) {
          formData.append("fileAttach", file.fileAttach as File);
          formData.append("fileName", (file.fileAttach as File).name);
        }
      } else {
        formData.append("fileAttach", file.fileAttach as File);
        formData.append("fileName", (file.fileAttach as File).name);
        formData.append("category", category);
      }
      formData.append("title", file.title as string);

      // if id was exist, update the existing file
      if (file.id) {
        try {
          if (file.fileAttach !== undefined) {
            return await axios(`${process.env.REACT_APP_API_URL}files/upload`, {
              method: "PATCH",
              data: formData,
            });
          }
          return await axios(
            `${process.env.REACT_APP_API_URL}files/uploadWithoutFile`,
            {
              method: "PATCH",
              data: formData,
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error: any) {
          throw new Error(error.message);
        }
      }

      // if id wasn't found, create a new file
      try {
        return await axios(process.env.REACT_APP_API_URL + "files/upload", {
          method: "POST",
          data: formData,
        });
      } catch (error: any) {
        throw new Error(error.message);
      }
    });

    // execute all
    await Promise.all(promises).then(() => {
      setData((prev) => ({
        ...prev,
        isLoading: false,
      }));
    });
  };

  const onAdd = () => {
    setData((prev) => ({
      ...prev,
      files: [
        ...prev.files,
        {
          title: "",
          fileAttach: undefined,
          fileName: undefined,
          category,
        },
      ],
    }));
    // setFiles((prev) => [
    //   ...prev,
    //   {
    //     title: "",
    //     fileAttach: undefined,
    //     fileName: undefined,
    //     category,
    //   },
    // ]);
  };

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value;
    const dataPosition = Number(e.target.name.split("-")[1]) - 1;

    setData((prev) => {
      const files = prev.files.slice();
      files[dataPosition] = {
        ...files[dataPosition],
        title,
      };

      return {
        ...prev,
        files,
      };
    });
    // setFiles((prev) => {
    //   const files = prev.slice();
    //   files[dataPosition] = {
    //     ...files[dataPosition],
    //     title,
    //   };
    //   return files;
    // });
  };

  const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const file = files?.[0];
    const dataPosition = Number(e.target.name.split("-")[1]) - 1;

    setData((prev) => {
      const files = prev.files.slice();
      files[dataPosition] = {
        ...files[dataPosition],
        fileAttach: file,
        fileName: (file as File).name,
      };

      return {
        ...prev,
        files,
      };
    });
  };

  const onDelete = async (id: number | undefined) => {
    if (id === undefined) {
      return setData((prev) => ({
        ...prev,
        files: prev.files.filter((item: IDataFiles) => item?.id !== id),
      }));
    }

    setData((prev) => ({
      ...prev,
      isLoading: true,
    }));

    try {
      const { data }: { data: { message: string; deleted: boolean } } =
        await axios(`${process.env.REACT_APP_API_URL}files/${id}`, {
          method: "DELETE",
        });
      if (data.deleted) {
        setData((prev) => ({
          ...prev,
          files: prev.files.filter((item: IDataFiles) => item?.id !== id),
        }));
        // setFiles((prev) => prev.filter((item: IDataFiles) => item?.id !== id));
      }
    } catch (error: any) {
      throw new Error(error.message);
    } finally {
      setData((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  if (data.isLoading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Oval
          height={80}
          width={80}
          color="#21928F"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#21928FCC"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );

  return (
    <div>
      <AdminHeader title={title} onSave={onSave} />
      <i>Only accepts: word, excel, pdf</i>
      <div className="space-y-2">
        {data.files.length > 0 &&
          data.files.map((file, index) => (
            <PDFCard
              key={index.toString()}
              order={index + 1}
              data={file}
              onDelete={() => onDelete(file.id)}
              onChangeFiles={onChangeFiles}
              onChangeTitle={onChangeTitle}
            />
          ))}
      </div>
      <div className="mt-4">
        <button
          onClick={onAdd}
          className="flex justify-between items-center rounded-md border-2 border-white pl-2 pr-4 py-2"
        >
          <div className="flex space-x-2 items-center w-full">
            <div className="rounded-md bg-blue-500 text-white px-2 py-2 text-lg">
              <MdAdd />
            </div>
            <p className="text-blue-500">Tambah baris</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default DownloadFilesComponent;
