import Section from "../Section";

const Galeri = () => {
  return (
    <Section title="Galeri" id="galeri">
      <div>
        <iframe
          src="https://id.litbangkestanbu.com/list-galeri-3/"
          className="w-full h-screen border-0"
          title="galeri"
        ></iframe>
      </div>
      {/* <div className="flex justify-center items-center">
        <ButtonGreenInverse
          link="https://id.litbangkestanbu.com/galeri/"
          text="Lainnya"
        />
      </div> */}
    </Section>
  );
};
export default Galeri;
