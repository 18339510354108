import axios from "axios";
import Section from "../Section";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import NavBack from "../NavBack";

const SejarahSelengkapnya = ({ title }: { title: string }) => {
  const [sejarah, setSejarah] = useState<
    {
      id: string;
      year: number;
      textarea: string;
    }[]
  >([]);

  useEffect(() => {
    const getFiles = async () => {
      try {
        const { data } = await axios(
          `${process.env.REACT_APP_API_URL}sejarah`,
          { method: "GET" }
        );
        setSejarah(data);
      } catch (error: any) {
        throw new Error(error.message);
      }
    };
    getFiles();
  }, []);

  return (
    <>
      <NavBack />
      <Section title={title} id="sejarah-lain-lain">
        <div className="flex flex-col gap-4">
          {sejarah &&
            sejarah.map((item) => (
              <div key={item.id}>
                <h4 className="font-bold text-primary-green text-lg text-left mb-4">
                  {`Tahun ${item.year}`}
                </h4>
                <div>
                  <pre className="whitespace-pre-wrap">
                    {parse(item.textarea)}
                  </pre>
                </div>
                <div className="w-full h-0.5 bg-gray-400/10 rounded-full mb-8" />
              </div>
            ))}
        </div>
      </Section>
    </>
  );
};
export default SejarahSelengkapnya;
