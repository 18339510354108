import Button from "../Button";

interface IAdminHeaderParams {
  title: string;
  buttonText?: string;
  showSubmit?: boolean;
  onSave: () => void;
}

const AdminHeader = ({
  title,
  buttonText = "Save",
  showSubmit = true,
  onSave,
}: IAdminHeaderParams) => {
  return (
    <div className="flex w-full justify-between items-center mb-4">
      <div>
        <h3 className="admin_title">{title}</h3>
      </div>
      <div className={`${!showSubmit && "hidden"}`}>
        <Button text={buttonText} onClick={onSave} />
      </div>
    </div>
  );
};
export default AdminHeader;
