import { useEffect, useState } from "react";
import Section from "../Section";
import axios from "axios";

interface IImage {
  id: number;
  title: string;
  filePath: string;
  fileName: string;
  category: string;
}

const StrukturOrganisasi = () => {
  const [image, setImage] = useState<IImage>();

  useEffect(() => {
    const getImage = async () => {
      try {
        const { data } = await axios(
          `${process.env.REACT_APP_API_URL}files/struktur-organisasi`
        );
        setImage(data.length > 0 ? data[0] : undefined);
      } catch (error: any) {
        throw new Error(error.message);
      }
    };
    getImage();
  }, []);

  return (
    <Section title="Struktur Organisasi" id="struktur-organisasi">
      <div>
        {image?.id && (
          <img
            src={process.env.REACT_APP_SERVER_URL + image?.filePath}
            alt="struktur organisasi"
            className="w-auto h-auto mx-auto"
          />
        )}
      </div>
    </Section>
  );
};
export default StrukturOrganisasi;
