import { useEffect, useState } from "react";
import Section from "../Section";
import LinkList from "../main/LinkList";
import axios from "axios";

interface IDataFiles {
  id: number;
  title: string;
  fileName: string;
  filePath: string;
  category: string;
}

const ZonaIntegritas = () => {
  const [files, setFiles] = useState<IDataFiles[]>([]);

  useEffect(() => {
    const getFiles = async () => {
      try {
        const { data } = await axios(
          `${process.env.REACT_APP_API_URL}files/zona-integritas`,
          { method: "GET" }
        );
        setFiles(data);
      } catch (error: any) {
        throw new Error(error.message);
      }
    };
    getFiles();
  }, []);

  return (
    <Section title="Zona Integritas" id="zona-integritas">
      <div>
        <div className="flex flex-col lg:flex-row lg:items-center gap-8">
          <div className="lg:flex-1">
            <img
              src="/assets/images/zona-integritas.png"
              alt="zona integritas"
              className="w-auto h-auto mx-auto"
            />
          </div>
          <div className="lg:flex-1">
            <div className="flex flex-col gap-4 lg:items-center">
              <LinkList data={files} link="/lainnya/zona-integritas" />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
export default ZonaIntegritas;
