import { useState, useEffect } from "react";
import Section from "../Section";
import ButtonGreenInverse from "../ButtonGreenInverse";
import axios from "axios";
import parse from "html-react-parser";

interface ISejarah {
  id: string;
  year: number;
  textarea: string;
}

const Sejarah = () => {
  const [sejarah, setSejarah] = useState<ISejarah[]>([]);

  useEffect(() => {
    const getSejarah = async () => {
      try {
        const { data } = await axios(`${process.env.REACT_APP_API_URL}sejarah`);
        setSejarah(data);
      } catch (error: any) {
        throw new Error(error.message);
      }
    };

    getSejarah();
  }, []);

  return (
    <Section title="Sejarah" id="sejarah">
      <div>
        <div className="flex flex-col lg:flex-row-reverse gap-4 lg:gap-8">
          {/* <div className="flex-1">
            <img
              src="https://source.unsplash.com/random"
              alt="random unsplash"
              className="w-full rounded-lg object-cover aspect-video"
            />
          </div> */}
          <div className="flex-1">
            <h4 className="font-bold text-primary-green text-lg text-left mb-4">
              {sejarah.length > 0 && `Tahun ${sejarah[0].year}`}
            </h4>
            <div>
              {sejarah.length > 0 && (
                <pre className="whitespace-pre-wrap">
                  {parse(sejarah[0].textarea)}
                </pre>
              )}
            </div>
            <br />
            <div>
              <ButtonGreenInverse link="/sejarah" text="Lainnya" />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
export default Sejarah;
