import { useState, useEffect } from "react";
import TupoksiCard from "../main/TupoksiCard";
import Section from "../Section";
import axios from "axios";

interface ITupoksi {
  id: string;
  textarea: string;
  title: string;
}

const Tupoksi = () => {
  const [tupoksi, setTupoksi] = useState<ITupoksi[]>([]);

  useEffect(() => {
    const getTupoksis = async () => {
      try {
        const { data } = await axios(`${process.env.REACT_APP_API_URL}tupoksi`);
        if (data.length > 0) {
          // const result = data.map((item: any) => ({
          //   ...item,
          //   title: "",
          //   textarea: "",
          // }));
          setTupoksi(data);
        }
      } catch (error: any) {
        throw new Error(error.message);
      }
    };

    getTupoksis();
  }, []);

  return (
    <Section title="Tupoksi" id="tupoksi">
      <div>
        <div className="flex flex-col gap-8 lg:flex-row">
          {/* card */}
          {tupoksi.length > 0 &&
            tupoksi.map((item, index) => {
              const imgUrl =
                index === 0
                  ? "/assets/images/tupoksi-1.png"
                  : "/assets/images/tupoksi-2.png";
              return (
                <TupoksiCard
                  key={item.id}
                  heading={item.title}
                  text={item.textarea}
                  imgUrl={imgUrl}
                />
              );
            })}
          <div></div>
        </div>
      </div>
    </Section>
  );
};
export default Tupoksi;
