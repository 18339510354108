import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Error from "./routes/error";
import Root from "./routes/root";
import AdminLayoutSlug from "./components/admin/layout";
import AdminVisiMisi from "./components/admin/AdminVisiMisi";
import AdminSejarah from "./components/admin/AdminSejarah";
import AdminStrukturOrganisasi from "./components/admin/AdminStrukturOrganisasi";
import AdminTupoksi from "./components/admin/AdminTupoksi";
import AdminGaleri from "./components/admin/AdminGaleri";
import AdminBerita from "./components/admin/AdminBerita";
import DownloadFilesComponent from "./components/admin/DownloadFilesComponent";
import Lainnya from "./components/main/Lainnya";
import BeritaCreate from "./components/admin/BeritaCreate";
import SejarahSelengkapnya from "./components/main/SejarahSelengkapnya";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
  },
  {
    path: "sejarah",
    errorElement: <Error />,
    element: <SejarahSelengkapnya title="Sejarah" />,
  },
  {
    path: "lainnya",
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <div />,
      },
      {
        path: "zona-integritas",
        element: (
          <Lainnya
            title="Zona Integritas Downloads"
            category="zona-integritas"
          />
        ),
      },
      {
        path: "pengumuman",
        element: <Lainnya title="Pengumuman Downloads" category="pengumuman" />,
      },
      {
        path: "format-surat",
        element: (
          <Lainnya title="Format Surat Downloads" category="format-surat" />
        ),
      },
      {
        path: "laporan-tahunan",
        element: (
          <Lainnya
            title="Laporan Tahunan Downloads"
            category="laporan-tahunan"
          />
        ),
      },
      {
        path: "laporan-kinerja",
        element: (
          <Lainnya
            title="Laporan Kinerja Downloads"
            category="laporan-kinerja"
          />
        ),
      },
      {
        path: "rencana-kegiatan",
        element: (
          <Lainnya
            title="Rencana Kegiatan Downloads"
            category="rencana-kegiatan"
          />
        ),
      },
      {
        path: "keuangan",
        element: <Lainnya title="Keuangan Downloads" category="keuangan" />,
      },
      {
        path: "kearsipan",
        element: <Lainnya title="Kearsipan Downloads" category="kearsipan" />,
      },
      {
        path: "lain-lain",
        element: <Lainnya title="Lain-lain Downloads" category="lain-lain" />,
      },
    ],
  },
  {
    path: "QBI1qhG5zDk8QvrTNAlWFymFtDPykujN",
    element: <AdminLayoutSlug />,
    children: [
      {
        index: true,
        element: <div>Klik salah satu menu</div>,
      },
      {
        path: "visi-misi",
        element: <AdminVisiMisi />,
      },
      {
        path: "sejarah",
        element: <AdminSejarah />,
      },
      {
        path: "struktur-organisasi",
        element: <AdminStrukturOrganisasi />,
      },
      {
        path: "tupoksi",
        element: <AdminTupoksi />,
      },
      {
        path: "zona-integritas",
        element: (
          <DownloadFilesComponent
            category="zona-integritas"
            title="Zona Integritas"
          />
        ),
      },
      {
        path: "galeri",
        element: <AdminGaleri />,
      },
      {
        path: "berita",
        element: <AdminBerita />,
      },
      {
        path: "pengumuman",
        element: (
          <DownloadFilesComponent category="pengumuman" title="Pengumuman" />
        ),
      },
      {
        path: "format-surat",
        element: (
          <DownloadFilesComponent
            category="format-surat"
            title="Format Surat"
          />
        ),
      },
      {
        path: "laporan-tahunan",
        element: (
          <DownloadFilesComponent
            category="laporan-tahunan"
            title="Laporan Tahunan"
          />
        ),
      },
      {
        path: "laporan-kinerja",
        element: (
          <DownloadFilesComponent
            category="laporan-kinerja"
            title="Laporan Kinerja"
          />
        ),
      },
      {
        path: "rencana-kegiatan",
        element: (
          <DownloadFilesComponent
            category="rencana-kegiatan"
            title="Rencana Kegiatan"
          />
        ),
      },
      {
        path: "keuangan",
        element: (
          <DownloadFilesComponent category="keuangan" title="Keuangan" />
        ),
      },
      {
        path: "kearsipan",
        element: (
          <DownloadFilesComponent category="kearsipan" title="Kearsipan" />
        ),
      },
      {
        path: "lain-lain",
        element: (
          <DownloadFilesComponent category="lain-lain" title="Lain-lain" />
        ),
      },
      {
        path: "berita/create",
        element: <BeritaCreate />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
