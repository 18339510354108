const Hero = () => {
  return (
    <section className="w-full h-screen bg_gradient" id="hero">
      <div className="container mx-auto h-screen">
        <div className="w-full h-full flex flex-col lg:flex-row justify-center items-center">
          <div className="flex-1 flex justify-center items-center mt-64 lg:mt-0">
            <div className="flex flex-col items-start justify-center gap-8 w-full pt-20 md:pt-32 lg:pt-0 lg:-mt-8 text-center lg:text-left">
              <h1 className="font-bold text-2xl lg:text-4xl text-white">
                BALAI PENELITIAN & <br className="hidden lg:block" />
                PENGEMBANGAN KESEHATAN
              </h1>
              <div className="flex flex-col gap-5 mx-auto lg:mx-0">
                <h3 className="font-semibold text-xl lg:text-2xl text-white">
                  Tanah Bumbu, Kalimantan Selatan
                </h3>
                <div className="w-14 h-2 bg-white rounded-lg mx-auto lg:mx-0" />
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center h-full flex-1 lg:mt-56">
            <iframe
              src="https://id.litbangkestanbu.com/slide-berita/"
              className="w-full border-0 h-screen"
              title="berita"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Hero;
